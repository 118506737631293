import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook14/image/imgPortadaEbook.png"
import img1 from "@components/pageEbook/ebooks/ebook14/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook14/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook14/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook14/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook14/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook14/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      Psicología de la venta: 15 principios que aseguran conversiones
    </h1>
  </>
)

const textDescription = (
  <p className="text-h2-ebook" style={{ marginTop: "20px" }}>
    Solo un 13% usuarios sienten que los vendedores realmente los entienden.
    Comprender a tus prospectos es crucial para tener éxito en las ventas. Saber
    qué los motiva, cuáles son sus problemas y cómo comunicarte, marca la
    diferencia en el cierre de una venta.
    <br />
    <br />
    Entender la psicología humana es un proceso complejo que requiere
    conocimiento y experiencia. Afortunadamente, existen algunos trucos de
    psicología de ventas que te ayudarán a comprender mejor a tus prospectos y
    aumentar tus posibilidades de éxito.
  </p>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    Puedes atraer la atención de potenciales compradores mostrando tu oferta
    como una promoción por tiempo limitado que no estará disponible nuevamente.
    Esta estrategia puede ser especialmente efectiva para productos de alto
    precio o para aquellos consumidores que compran por impulso.
    <br /> <br />
    Es esencial entender las necesidades, intereses y obstáculos de acción de tu
    mercado objetivo para crear un mensaje convincente.
  </p>
)
const textTwo = (
  <p>
    Para que tu oferta por tiempo limitado sea realmente efectiva, puedes
    aprovechar técnicas de narración para crear una conexión emocional con los
    posibles clientes.
    <br /> <br />
    Contar historias personales sobre cómo tu producto o servicio ha beneficiado
    la vida de las personas puede apelar a las emociones de tu audiencia y
    ganarse su confianza.
  </p>
)
const textThree = (
  <p>
    Es una forma eficiente de perfilar a alguien, en este caso, tu cliente. Este
    modelo de comportamiento identifica cuatro tipos de personalidad clave:
    Analíticos, Conductores, Amables, Expresivos.
  </p>
)

const data = {
  start: {
    support: "Ebook | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Ebook",
    img: imgPortadaEbook,
  },

  slider: {
    title: true,
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "¿Qué es la psicología en ventas?",
      },
      {
        key: 2,
        text: "Cómo crear una estrategia de psicología en ventas",
      },
      {
        key: 3,
        text: "15 principios de psicología de venta",
      },
      {
        key: 4,
        text: "4 consejos de psicología de ventas para un cierre",
      },
      {
        key: 5,
        text:
          "Atraer la atención de potenciales compradores como una promoción por tiempo limitado",
      },
      {
        key: 6,
        text: "Identificar cuatro tipos de personalidad clave",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title: "Crea un sentido y ofertas de urgencia",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Utiliza técnicas de storytelling  ",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Método Merrill-Reid  ",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
